<template>
  <div class="dashboard-container">
    <div class="project-header">
      <!-- <router-link class="exit" to="/resetPassword">
            {{ $t('navbar.updatePwd') }}
      </router-link> -->
      <el-button @click="onExit" class="exit" size="small">退出</el-button>
      <el-button @click="$router.push('/resetPassword')" class="exit" size="small">{{ $t('navbar.updatePwd') }}</el-button>
      <el-button @click="openSystemConf" class="exit" size="small" icon="el-icon-setting">常用系统设置</el-button>
    </div>
    <div class="project-content">
      <!--为测试和开发，先本地写个跳转到本系统-->
      <!-- <div @click="goSystem(0)" class="project-box">三态认证系统</div> -->
      <div
        v-for="(item,index) of showSystemList"
        :key="index"
        @click="goSystem(item)"
        class="project-box"
      >{{item.usName}}</div>
    </div>
    <div id="main" ref="echarts-container" class="echarts-container" :style="echartsHeight"></div>

    <!-- 常用系统配置弹窗 -->
    <system-conf-dialog v-bind.sync="confObj" @submitSystemConf="submitSystemConf" @closeDialog="closeDialog"></system-conf-dialog>
  </div>
</template>
<script>
import echarts from 'echarts'
import 'echarts/map/js/world.js'
import { mapGetters } from 'vuex'
import { userCenterUserSystemMapNew } from '@/api/system-list'
import {
  setSystemToken
} from '@/utils/systemid'
import { bussinessTreeNew } from '@/api/menu-manage'
import Cookies from 'js-cookie'
import systemConfDialog from './components/systemConfDialog.vue'
export default {
  components: { systemConfDialog },
  name: 'Dashboard',
  data() {
    return {
      type: '',
      confObj: {
        confVisible: false,
        showSystemList: [],
        hiddenSystem: []
      },
      showSystemList: [],
      hiddenSystem: []
    }
  },
  computed: {
    ...mapGetters(['token', 'roles']),
    echartsHeight() {
      return `height:${window.innerHeight}px`
    }
  },
  mounted() {
    this.initEcharts()
    this.getSystemList()
    this.checkBrowser()
  },
  methods: {
    getSystemList() {
      userCenterUserSystemMapNew()
        .then(response => {
          // this.systemList = response.data
          const allSystemList = response.data.overallSystem || []
          this.hiddenSystem = response.data.hiddenSystem || []
          if (this.hiddenSystem.length > 0) {
            const hiddenIds = this.hiddenSystem.map(item => item.usId)
            this.showSystemList = allSystemList.filter(item => !hiddenIds.includes(item.usId))
          } else {
            this.showSystemList = allSystemList
          }

          this.showSystemList.forEach(v => {
            if (Number(this.$route.query.usId) === v.usId) {
              this.goSystem(v)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    goSystem(item) {
      console.log(item)
      // 在跳转前加一个请求，以防用户长时间停留在这个页面导致token过期了还跳转至其它系统
      bussinessTreeNew(item.usId).then(() => {
        // 权限系统 跳转其它系统后cookie里面的systemid会被覆盖
        // 现解决方法：权限系统1，新权限系统测试243，新权限系统本地245
        if (item.usId === Number(process.env.VUE_APP_SYSTEM_ID_SAVE)) {
          setSystemToken(item.usId)
        }
        this.$store.dispatch('GenerateRoutes', {}).then(() => {
          // 根据roles权限生成可访问的路由表
          this.$router.addRoutes(this.$store.getters.addRouters) // 动态添加可访问路由表
          console.log(this.roles, 'ddd')
          let object = {
            userId: this.roles.userInfo.userId,
            systemCode: item.usId,
            username: this.roles.userInfo.username,
            ip: Cookies.get('userIp'),
            token: this.token
            // userInfo: this.roles.userInfo
            // originUrl: window.location.href （后端校验token不通过，后期后端做处理后，在子系统可用传递的URL做回退，先写死）
          }
          // 只有权限系统加上这个 其它系统不需要
          if (item.usId === Number(process.env.VUE_APP_SYSTEM_ID_SAVE)) {
            object.userInfo = JSON.stringify(this.roles.userInfo)
          }
          object = JSON.stringify(object)
          const Base64 = require('js-base64').Base64
          const urlCode = Base64.encode(object)
          //   window.open(`http://${item.usUrl}?code=${urlCode}`);
          var browser = {
            versions: (function() {
              var u = navigator.userAgent
              return {
                // 移动终端浏览器版本信息
                trident: u.indexOf('Trident') > -1, // IE内核
                presto: u.indexOf('Presto') > -1, // opera内核
                webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
                iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, // 是否iPad
                webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
              }
            })(),
            language: (
              navigator.browserLanguage || navigator.language
            ).toLowerCase()
          }
          if (browser.versions.mobile) {
            // 判断是否是移动设备打开。browser代码在下面
            /* var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) === "micromessenger") {
            //在微信中打开
            setInterval(WeixinJSBridge.call('closeWindow'),2000);
            }
            if (ua.match(/WeiBo/i) === "weibo") {
            //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) === "qq") {
            //在QQ空间打开
            }
            if (browser.versions.ios) {
            //是否在IOS浏览器打开
            }
            if(browser.versions.android){
            //是否在安卓浏览器打开
            }*/
            // window.location.href = `http://${item.usUrl}?code=${urlCode}`
            window.location.href = `http://${item.usUrl}?code=${urlCode}`
            if (item.usUrl.indexOf('http') > -1) {
              window.location.href = `${item.usUrl}?code=${urlCode}`
            } else {
              window.location.href = `http://${item.usUrl}?code=${urlCode}`
            }
          } else {
            // window.open(`http://${item.usUrl}?code=${urlCode}`)
            if (item.usUrl.indexOf('http') > -1) {
              // url 对: //不转义 丢失
              const decode = decodeURIComponent(item.usUrl)
              window.open(`${decode}?code=${urlCode}`)
            } else {
              window.open(`http://${item.usUrl}?code=${urlCode}`)
            }
          }
        })
      })
    },
    onExit() {
      this.$store.dispatch('LogOut').then(() => {
        if (this.type === 'PC') {
          location.reload()
        } else if (this.type === 'mobile') {
          window.location.href = `http://account.suntekcorps.com:8080` // 生产
          // window.location.href=`http://190.168.1.50:8060/`; // 测试
          // window.location.href=`http://localhost:8089/`; // 本地测试
        }
        // location.reload(); // 为了重新实例化vue-router对象 避免bug
      })
    },
    // 判断当前浏览器类型
    checkBrowser() {
      var browser = {
        versions: (function() {
          var u = navigator.userAgent
          return {
            // 移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
          }
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase()
      }
      if (browser.versions.mobile) {
        // 判断是否是移动设备打开。browser代码在下面
        /* var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) === "micromessenger") {
            //在微信中打开
            setInterval(WeixinJSBridge.call('closeWindow'),2000);
            }
            if (ua.match(/WeiBo/i) === "weibo") {
            //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) === "qq") {
            //在QQ空间打开
            }
            if (browser.versions.ios) {
            //是否在IOS浏览器打开
            }
            if(browser.versions.android){
            //是否在安卓浏览器打开
            }*/
        this.type = 'mobile'
      } else {
        this.type = 'PC'
      }
    },
    initEcharts() {
      // 获取echarts的容器
      var chart = echarts.init(document.getElementById('main'))

      /*
        图中相关城市经纬度,根据你的需求添加数据
        关于国家的经纬度，可以用首都的经纬度或者其他城市的经纬度
      */
      var geoCoordMap = {
        三态股份: [113.9508, 22.563],
        广州: [113.5107, 23.2196],
        西安: [108.947, 34.347],
        芬兰: [24.909912, 60.169095],
        美国: [-100.696295, 33.679979],
        日本: [139.710164, 35.706962],
        韩国: [126.979208, 37.53875],
        瑞士: [7.445147, 46.956241],
        东南亚: [117.53244, 5.300343],
        澳大利亚: [135.193845, -25.304039],
        德国: [13.402393, 52.518569],
        英国: [-0.126608, 51.208425],
        加拿大: [-102.646409, 59.994255]
      }

      /*
        记录下起点城市和终点城市的名称，以及权重
        数组第一位为终点城市，数组第二位为起点城市，以及该城市的权重，就是图上圆圈的大小
      */

      // 西安
      //   var CQData = [
      //     [{ name: "西安" }, { name: "芬兰", value: 30 }],
      //     [{ name: "西安" }, { name: "德国", value: 90 }],
      //     [{ name: "西安" }, { name: "英国", value: 30 }],
      //     [{ name: "西安" }, { name: "韩国", value: 30 }]
      //   ];

      // 广州
      //   var GZData = [
      //     [{ name: "广州" }, { name: "日本", value: 30 }],
      //     [{ name: "广州" }, { name: "东南亚", value: 30 }]
      //   ];

      // 三态股份
      var STData = [
        [{ name: '三态股份' }, { name: '西安', value: 30 }],
        [{ name: '三态股份' }, { name: '加拿大', value: 100 }],
        [{ name: '三态股份' }, { name: '美国', value: 30 }],
        [{ name: '三态股份' }, { name: '澳大利亚', value: 95 }],
        [{ name: '三态股份' }, { name: '瑞士', value: 30 }],
        [{ name: '三态股份' }, { name: '德国', value: 30 }],
        [{ name: '三态股份' }, { name: '芬兰', value: 30 }],
        [{ name: '三态股份' }, { name: '东南亚', value: 30 }],
        [{ name: '三态股份' }, { name: '日本', value: 30 }],
        [{ name: '三态股份' }, { name: '韩国', value: 30 }]
      ]

      // 小飞机的图标，可以用其他图形替换
      var planePath =
        'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'

      // 获取地图中起点和终点的坐标，以数组形式保存下来
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i]
          var fromCoord = geoCoordMap[dataItem[1].name]
          var toCoord = geoCoordMap[dataItem[0].name]
          if (fromCoord && toCoord) {
            res.push([
              {
                coord: fromCoord // 起点坐标
              },
              {
                coord: toCoord // 终点坐标
              }
            ])
          }
        }
        return res
      }

      var color = ['#9ae5fc', '#dcbf71'] // 自定义图中要用到的颜色
      var series = [] // 用来存储地图数据

      /*
        图中一共用到三种效果，分别为航线特效图、飞机航线图以及城市图标涟漪图。
        要用到setOption中的series属性，并且对每个城市都要进行三次设置。
      */
      // [["西安", CQData], ["广州", GZData], ["三态股份", STData]].forEach(function(
      ;[['三态股份', STData]].forEach(function(item, i) {
        series.push(
          {
            // 白色航线特效图
            type: 'lines',
            zlevel: 1, // 用于分层，z-index的效果
            effect: {
              show: true, // 动效是否显示
              period: 6, // 特效动画时间
              trailLength: 0.7, // 特效尾迹的长度
              color: '#fff', // 特效颜色
              symbolSize: 3 // 特效大小
            },
            lineStyle: {
              normal: {
                // 正常情况下的线条样式
                color: color[0],
                width: 0, // 因为是叠加效果，要是有宽度，线条会变粗，白色航线特效不明显
                curveness: -0.2 // 线条曲度
              }
            },
            data: convertData(item[1]) // 特效的起始、终点位置
          },
          {
            // 小飞机航线效果
            type: 'lines',
            zlevel: 2,
            // symbol: ['none', 'arrow'],   // 用于设置箭头
            symbolSize: 10,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: planePath, // 特效形状，可以用其他svg pathdata路径代替
              symbolSize: 15
            },
            lineStyle: {
              normal: {
                color: color[0],
                width: 1,
                opacity: 0.6,
                curveness: -0.2
              }
            },
            data: convertData(item[1]) // 特效的起始、终点位置，一个二维数组，相当于coords: convertData(item[1])
          },
          {
            // 散点效果
            type: 'effectScatter',
            coordinateSystem: 'geo', // 表示使用的坐标系为地理坐标系
            zlevel: 3,
            rippleEffect: {
              brushType: 'stroke' // 波纹绘制效果
            },
            label: {
              normal: {
                // 默认的文本标签显示样式
                show: true,
                position: 'left', // 标签显示的位置
                formatter: '{b}' // 标签内容格式器
              }
            },
            itemStyle: {
              normal: {
                color: color[0]
              }
            },
            data: item[1].map(function(dataItem) {
              return {
                name: dataItem[1].name,
                value: geoCoordMap[dataItem[1].name], // 起点的位置
                symbolSize: dataItem[1].value / 8 // 散点的大小，通过之前设置的权重来计算，val的值来自data返回的value
              }
            })
          }
        )
      })

      // 显示终点位置,类似于上面最后一个效果，放在外面写，是为了防止被循环执行多次
      series.push({
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 3,
        rippleEffect: {
          brushType: 'stroke'
        },
        label: {
          normal: {
            show: true,
            position: 'left',
            formatter: '{b}'
          }
        },
        symbolSize: function(val) {
          return val[2] / 8
        },
        itemStyle: {
          normal: {
            color: color[1]
          }
        },
        data: [
          //   {
          //     // 这里面的数据，由于一开始就知道终点位置是什么，所以直接写死，如果通过ajax来获取数据的话，还要进行相应的处理
          //     name: "西安",
          //     value: [108.3626, 22.8341],
          //     label: {
          //       normal: {
          //         position: "top"
          //       }
          //     }
          //   },
          //   {
          //     name: "广州",
          //     value: [113.5107, 23.2196, 30],
          //     label: {
          //       normal: {
          //         position: "right"
          //       }
          //     }
          //   },
          {
            name: '三态股份',
            value: [113.9508, 22.563, 30]
          }
        ]
      })

      // 最后初始化世界地图中的相关数据
      chart.setOption({
        title: {
          text: '', // 地图标题
          textStyle: {
            color: '#fff',
            fontSize: 25
          },
          top: '20px',
          left: '48%'
        },
        geo: {
          map: 'world', // 与引用进来的地图js名字一致
          roam: false, // 禁止缩放平移
          itemStyle: {
            // 每个区域的样式
            normal: {
              areaColor: '#323c48'
            },
            emphasis: {
              areaColor: '#2a333d'
            }
          },
          regions: [
            {
              // 选中的区域
              name: 'China',
              selected: true,
              itemStyle: {
                // 高亮时候的样式
                emphasis: {
                  areaColor: '#7d7d7d'
                }
              },
              label: {
                // 高亮的时候不显示标签
                emphasis: {
                  show: false
                }
              }
            }
          ]
        },
        series: series, // 将之前处理的数据放到这里
        textStyle: {
          fontSize: 12
        }
      })
    },
    openSystemConf() {
      this.confObj = {
        confVisible: true,
        hiddenSystem: this.hiddenSystem,
        showSystemList: this.showSystemList
      }
    },
    closeDialog() {
      this.confObj.confVisible = false
    },
    submitSystemConf(showSystemList, hiddenSystem) {
      this.showSystemList = JSON.parse(JSON.stringify(showSystemList))
      this.hiddenSystem = JSON.parse(JSON.stringify(hiddenSystem))
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.project {
  &-header {
    width: 99%;
    // text-align: center;
    position: absolute;
    left: 0;
    padding-top: 10px;
    height: 40px;
  }
  &-content {
    // text-align: center;
    margin-top: 50px;
    position: absolute;
    left: 0;
    z-index: 1;
    clear: both;
  }
  &-box {
    display: inline-block;
    font-size: 20px;
    color: #ffffff;
    margin: 20px;
    padding: 10px 20px;
    // background-image: url("../../assets/404_images/11.png");
    background-image: url('./11.png');
    border: 2px solid rgba(104, 104, 110, 0.6);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.15s;
  }
  &-box:hover {
    transform: scale(1.2);
  }
}
/**echarts容器样式**/
.echarts {
  &-container {
    padding-top: 5%;
    font-size: 15px;
    // background-image: url("../../assets/404_images/11.png");
    background: url('./11.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
    //隐藏滚动条，防止鼠标移入放大动画导致滚动条出现而屏幕闪烁
    overflow: hidden;
  }
}
.exit {
  float: right;
  margin: 20px 20px 0 0;
  background: #ffffff44;
  color: #9ae5fc;
  border-color: transparent;
  border-width: 2px;
  box-shadow: inset 0 0 2px 0px #93e6ffaa, 0 0 4px 1px #93e6ffaa, 0 0 4px 0px #93e6ff88;

}
.exit:hover {
  box-shadow: inset 0 0 2px 0px #93e6ffaa, 0 0 4px 3px #93e6ffaa, 0 0 4px 2px #93e6ff88;
  background-color:#ffffff22;
  transition: .5s
}
 .project-box:hover{
    transform: scale(1.1);
    border-color: #fff;
    opacity:1;
 }
 .dashboard-container {
    background: #ffffff16;
 }
</style>
